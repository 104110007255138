// @use '@carbon/react';
// @import 'carbon-components/scss/globals/scss/styles.scss';

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; 
}

body {
  overflow: hidden;
}