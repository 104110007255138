/* App */

html, body {
  background: #111;
}

.App {
  text-align: left;
  margin: 0px;
  margin-bottom: -30px;
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  background-color: var(--cds-ui-background,#262626);
}

/* On screens that are over 1024px, set the app to a max-width of 500px -> (somewhat) prevents weirdness for tablets (they usually go up to 1024px) */
@media screen and (min-width: 1024px) {
  html, body {
    background: #111;
  }

  .App,
  .settings-modal {
    max-width: 500px !important;
  }
}


/* Header */

.smheader {
  min-height: 80px;
  background-color: #161616;
  color: #F4F4F4;
  display: flex;
  flex-direction: column;
}

.smheader .header-empty {
  min-height: 32px;
}

.smheader .header-line {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  text-align: center;
  margin-right: 1px;
}

.smlogofont {
  margin: auto;

  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
}


/* Settings */

.settings-modal {
  position: fixed;
  top: 148px;
  left: 0px;
  background-color: #2B2B2B;
  padding-left: 4px;
  padding-right: 4px;
  z-index: 1000;
  width: 100% !important;
}

.grow {
  flex-grow: 10;
}


.refresh-button {
  display: flex;
  margin: auto;
  min-width: 48px;
  min-height: 48px;
  height: 48px;
}


/* Buttons */

.bx--btn {
    max-width: unset;
    width: 100%;
}

.bx--btn--secondary {
    background-color: #424242;
    border-top: 1px solid #525252;
}

.sm-row .bx--btn {
    width: auto;
}

.button-dark {
  background-color: #393939;
  border-left: 1px solid #525252;
}
.button-dark:focus {
  background-color: #393939;
  outline: none !important;
  border: none !important;
  border-width: 0px;
}
.bx--btn__icon:focus {
  outline: none !important;
}

.settings-on {
  background-color: #AD3436;
  color: white;
  outline: none;
  border: none;
}
.settings-on:hover {
  background-color: #AD3436;
  color: white;
  outline: none;
  border: none;
}
.settings-off {
  background-color: #393939;
  color: white;
  outline: none;
  border: none;
}
.settings-off:hover {
  background-color: #393939;
  color: white;
  outline: none;
  border: none;
}

.solo-btn-on {
  background-color: #42BE65;
  color: #2B2B2B;
}
.solo-btn-on:hover {
  background-color: #42BE65;
  color: #2B2B2B;
}
.solo-btn-off {
  background-color: #393939;
}
.solo-btn-off:hover {
  background-color: #393939;
}

.mute-btn-on {
  background-color: #FF8389;
  color: #2B2B2B;
}
.mute-btn-on:hover {
  background-color: #FF8389;
  color: #2B2B2B;
}
.mute-btn-off {
  background-color: #393939;
}
.mute-btn-off:hover {
  background-color: #393939;
}

.lock-btn-on {
  background-color: #F1C21B;
  color: #2B2B2B;
}
.lock-btn-on:hover {
  background-color: #F1C21B;
  color: #2B2B2B;
}
.lock-btn-off {
  background-color: #393939;
}
.lock-btn-off:hover {
  background-color: #393939;
}

.sync-all-tracks-on {
  background-color: #8DC6FD;
  color: #2B2B2B;
}
.sync-all-tracks-on:hover {
  background-color: #8DC6FD;
  color: #2B2B2B;
}
.sync-all-tracks-off {
  background-color: #2B2B2B;
}
.sync-all-tracks-off:hover {
  background-color: #2B2B2B;
}


/* Utility */

.sm-margin-auto {
  margin: auto;
}

.sm-icon {
  margin: auto;
  margin-left: 1em;
}

.manual-connection {
  display: none; /* HIDE THE MANUAL CONNECTION DIALOG */
}

.section-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #393939;
  height: 48px;  
}

.connection-panel {
  margin-left: 40px;
  margin-right: 40px;
}
.tunnel-row {  
  min-height: 48px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #525252;
}
.tunnel-row-child {  
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
}
.tunnel-row-child.offline {  
  color: rgb(125, 125, 125);
}
.tunnel-circle {
  min-height: 12px;
  min-width: 12px;
  max-height: 12px;
  max-width: 12px;
  border-radius: 50%;
  margin-left: 1em;
  margin-top: auto;
  margin-bottom: auto;
  background-color: #42BE65;
}
.tunnel-circle.offline {
  background-color: #656565;
}
.tunnel-icon {
  width: auto;
  height: auto;
  margin: auto;
  margin-right: 1em;
}
.tunnel-icon.offline {
  opacity: 0.5;
}

.sm-row {
  min-height: 48px;
  display: flex;
  flex-direction: row;
  background-color: #393939;
  border-top: 1px solid #525252;
}

.sm-lighter{
  background-color: #424242;
}

.margin-right {
  margin-right: 1em;
}

/* hide parameter divider */
.parameter-divider {
  display: none;
}


.content_scroller {
  /* height: 300px; */
  overflow: auto;
  overflow-x: hidden;
}

.widget_settings {
  /* height: 300px; */
  overflow: auto;
}

.settings-modal {
  overflow: auto;
  width: 100%;
}

.no-wrap {
  white-space: nowrap;
}



/* Track selector */

.color-circle {
  min-height: 24px;
  min-width: 24px;
  max-height: 24px;
  max-width: 24px;
  border-radius: 50%;
  margin-left: 1em;
  margin-top: auto;
  margin-bottom: auto;
  background-color: #42BE65;
}
.color-circle-0 {
  background-color: #F4F4F4;
}
.color-circle-1 {
  background-color: #AD3436;
}
.color-circle-2 {
  background-color: #F1C21B;
}
.color-circle-3 {
  background-color: #5C7080;
}
.color-circle-4 {
  background-color: #5C7080;
}
.color-circle-5 {
  background-color: #5C7080;
}
.color-circle-6 {
  background-color: #5C7080;
}
.color-circle-7 {
  background-color: #5C7080;
}
.color-circle-8 {
  background-color: #5C7080;
}
.color-circle-9 {
  background-color: #5C7080;
}
.color-circle-10 {
  background-color: #5C7080;
}
.color-circle-11 {
  background-color: #5C7080;
}
.color-circle-12 {
  background-color: #5C7080;
}
.color-circle-13 {
  background-color: #5C7080;
}
.color-circle-14 {
  background-color: #5C7080;
}
.color-circle-15 {
  background-color: red;
}

.track-note-count {
  margin-right: 16px;
}
.track-selector {
  min-height: 48px;
}


/* Dropdown */

.dropdown-label {
  margin: auto;
}
.dropdown-label-margin-left {
  margin-left: 1em;
  margin-top: auto;
  margin-bottom: auto;
}

.dropdown-value {
  white-space: nowrap;
  margin: auto;
  margin-right: 1em;
}


/* Toggle */

.bx--toggle-input__label {
  margin: auto;
}
.bx--toggle__switch {
  margin: 0px !important;
}



/* Label and Textinput */

.bx--text-input {
  text-align: right;
  border-bottom: none !important;
  font-family: 'IBM Plex Mono', Courier, monospace;
}




/* Slider */

/* dropdown slider */
.dropdown-slider {
  min-height: 48px;
}
/* .dropdown-slider */
.bx--list-box__field {  
  padding-right: 0px;
  min-height: 48px;
}
/* .dropdown-slider */
.bx--list-box__field
.bx--list-box__menu-icon {
  display: none;
}
.dropdown-slider .bx--list-box__menu .bx--list-box__menu-item {
  min-height: 48px;
}
/* .dropdown-slider */
.bx--list-box__menu
.bx--list-box__menu-item
.bx--list-box__menu-item__option {
  height: 48px;
  min-height: 48px;
  padding: 0px;
  margin: 0px;
}
/* .dropdown-slider */
.bx--list-box__menu
.bx--list-box__menu-item
.bx--list-box__menu-item__option
.bx--list-box__menu-item__selected-icon {
  display: none;
}
/* .dropdown-slider */
.bx--list-box__menu
.bx--list-box__menu-item
.bx--list-box__menu-item__option
.bx--form-item
.bx--slider-container {
  /* adjust slider width? */
  min-height: 48px;
}
/* .dropdown-slider */
.bx--list-box__menu
.bx--list-box__menu-item
.bx--list-box__menu-item__option
.bx--form-item
.bx--label {
  display: none;
}

/* .dropdown-slider */
.bx--list-box__menu
.bx--list-box__menu-item
.bx--list-box__menu-item__option
.bx--form-item
.bx--slider-container
.bx--slider-text-input {
  /* adjust slider width? */
  min-height: 48px;
  min-width: auto;
  height: auto;
}


/* Dropdown */

.bx--dropdown__wrapper {
  min-height: 48px;
}

.bx--dropdown {
  min-height: 48px;
}

.sm-row-maxheight {
  max-height: 48px;
}


.in-dropdown-button {
  border-left: 1px solid #525252;
}
.in-dropdown-button:focus {
  outline: none !important;
  border: none !important;
}
/* dropdown */
.in-dropdown-button.bx--btn--secondary {
  /* margin: 0 !important; */
  /* width: 20px !important;
  min-width: 20px !important;
  max-width: 20px !important; */
  /* flex-grow: 0; */
  padding: 4px;
  /* height: 100%; */
  /* min-height: 0; */
}
.in-dropdown-button.bx--btn--sm {
  padding: 10px;
  min-height: 0;
}



.bx--list-box__label,
.cds--list-box__label {
  width: 100%;
}

/* only set size for e.g.: track selector menu items? */
/* .bx--list-box__menu-item {
  min-height: 48px;
} */


/* Tabs */

.bx--tab-content {
  padding: 0px !important;
}

.bx--tabs--scrollable__nav-link {
  width: auto !important;
}

.bx--tabs--scrollable__nav {
  flex: 1000;
  border-bottom: 1px solid var(--cds-ui-03,#535353);
}

.bx--tabs--scrollable__nav-item {
  flex: 1;
  flex-direction: column;
}

.bx--tabs--scrollable .bx--tabs--scrollable__nav-link:active, .bx--tabs--scrollable .bx--tabs--scrollable__nav-link:focus {
  outline: none !important;
}

ul.bx--tabs--scrollable__nav {
  border-bottom: 1px solid var(--cds-ui-03,#535353);
}

.bx--tabs--scrollable .bx--tabs--scrollable__nav-link:active, .bx--tabs--scrollable .bx--tabs--scrollable__nav-link:focus {
  outline: none !important;
}

.bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item {
    background-color: #e0e0e0;
    background-color: var(--cds-ui-03,#e0e0e0);
    border: 1px solid var(--cds-ui-background,#262626);
}

.bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item--selected, .bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item--selected:hover {
    background-color: #f4f4f4;
    background-color: var(--cds-ui-01,#f4f4f4) !important;
}

.bx--tabs--scrollable.bx--tabs--scrollable--container .bx--tabs--scrollable__nav-item+.bx--tabs--scrollable__nav-item {
    box-shadow: none !important;
}


/* Indent items till we have the new style */

.bx--label, .bx--slider-container, .bx--checkbox-wrapper {
  margin-left: 15px;
}



/* Accordion */

.bx--accordion__content {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.bx--accordion__item--active button.bx--accordion__heading {
    border-bottom: 1px solid var(--cds-ui-03,#535353);
}

.bx--accordion__heading {
    padding: 0.9rem 0;
    height: 48px;
}

.bx--accordion__title {
  font-weight: var(--cds-productive-heading-02-font-weight,600);
}

.parameter-wrapper:last-child .bx--accordion__item {
    border-bottom: 1px solid var(--cds-ui-03,#e0e0e0);
}

.bx--accordion__item:last-child {
  border-bottom: none;
}
    
.bx--accordion__item--active .bx--accordion__content {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}




/* ImageButton */

.parameter-wrapper.selected {
  border: 5px solid #ffffff;
  opacity: 0.7;
}

.widget_imagebutton .flex-h-only,
.parameter-wrapper.selected .flex-h-only {
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  max-height: 300px; /* Maximum height for the container */
  background-color: #111;
}

.flex-h-only .flex-h-only .image-title.flex-h {
  display: none; /* Fix for duplicate image title */
}


/* Preview Images */
#Preview .parameter-image {
  max-height: unset !important;
}

#Preview .image-title {
  display: none;
}


/* gui styles */

.parameter-label {
  white-space: nowrap;
  color: gray;
  font-size: 16;  
  margin-bottom: 4px;
}

.parameter-wrapper
.bx--accordion__item
.bx--accordion__heading {
  min-height: 46px;
}
.parameter-wrapper {
  margin-top: 0px;
  min-height: 46px;
}

.image-title {
  background-color: #39393999;
  position: absolute;
  min-height: 48px;
  width: 100%;
  bottom: 0px;
}
.margin-left {
  margin-left: 1em;
}
.parameter-image {
  max-width:100%;
  max-height: 300px; /* Maximum height for the image */
  width: auto;
  height: auto;
  flex-grow: 10;
  object-fit: contain; /* Maintain aspect ratio without cropping */
}
.parameter-imagebutton-inactive {
  filter: grayscale(100%) opacity(40%);
  -webkit-filter: grayscale(100%) opacity(40%);  
}
.parameter-imagebutton-inactive {
  filter: grayscale(100%) opacity(40%);
  -webkit-filter: grayscale(100%) opacity(40%);  
}
.imagebutton-pressed {
  filter: opacity(60%);
  -webkit-filter: opacity(60%);  
}


.radio-option-container {
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
}
.radio-option-item {
  flex-grow: 10;
  white-space: nowrap;
  width: auto;
  padding-right: 15px;
}

/* .rootgroup-wrapper {
  padding: 5px;
  padding-top: 0px;
  padding-bottom: 4px;
  border-radius: 4px;
  border: 1px solid #454545;
} */

.serverid {
  font-size: 0.8em;
  margin-top: 5px;
  margin-left: 2px;
  color: #5C7080;
}

.credits {
  font-size: 0.8em;
  margin-top: 2em;
  margin-bottom: 1em;
  width: 100%;
  text-align: center;
  font-size: 0.8em;
}

.aunderline {
  color: #5C7080;
  text-decoration: "underline";
}

.grouplabel {
  margin: auto;
  font-size: 1.2em !important;
}

.parameter-wrapper .h_element:first-child {
  flex-grow: 10;
}
.parameter-wrapper .h_element:not(:first-child) {
  flex-grow: 0;
}

.spacer {
  flex-grow: 2;
}

.flex-h {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-h-only {
  display: flex;
  flex-direction: row;
  position: relative;
}

.horizontallayout {

}

.h-element {
  border-left: 1px solid #525252;
}
.h-element
section
{
  display: flex;
}

.h-element
.bx--btn
{
  flex-grow: 10;
}

/* 
* color
*/
.color-field {
  width: 50%;
  padding: 0;
  border: none;
  outline: none;
}
.color-field::-webkit-color-swatch-wrapper {
  padding: 0; 
}
.color-field::-webkit-color-swatch {
  border: none;
  border-radius: 0;
}
.color-field::-moz-color-swatch {
  border: none;
  border-radius: 0;
  padding: 0;
}
.color-label {
  margin-left: 1em;
  margin-top: auto;
  margin-bottom: auto !important;
}



/* 
* carbon design system overrides
*/

.bx--modal-close {
  display: none;
}