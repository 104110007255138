$feature-flags: (
  enable-css-custom-properties: true,
);

@import 'carbon-components/scss/globals/scss/css--helpers';
@import 'carbon-components/scss/globals/scss/vendor/@carbon/elements/scss/themes/mixins';

.white {
    @include carbon--theme($carbon--theme--white, true);
}

.g10 {
    @include carbon--theme($carbon--theme--g10, true);
}

.g80 {
    @include carbon--theme($carbon--theme--g80, true);
}

.g90 {
    @include carbon--theme($carbon--theme--g90, true);
}

.g100 {
    @include carbon--theme($carbon--theme--g100, true);
}

.v9 {
    @include carbon--theme($carbon--theme--v9, true);
}

// import Carbon styles
@import 'carbon-components/scss/globals/scss/styles.scss';
